import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "environments/environment";
import {
  BehaviorSubject,
  concatMap,
  delay,
  forkJoin,
  from,
  Observable,
  of,
  Subject,
  Subscription,
  tap,
  toArray,
} from "rxjs";
import {
  DocData,
  DocTipo,
  Documento,
  DocumentoCorrelatoUtente,
} from "../models/documento";
import { IRowNode } from "ag-grid-community";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { catchError } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class DocumentiService {
  // forkJoin(this.obs.map(o => o.pipe(tap(() => this.count++))))
  // .subscribe();
  constructor(private _http: HttpClient) {}

  selectedDocumentIds: Array<any> = [];
  private downloadProgressSubject = new BehaviorSubject<number>(0);
  downloadProgress$ = this.downloadProgressSubject.asObservable();
  private totalDownloads = 0;
  completedDownloads: number = 0;
  isDownloadingSubject = new BehaviorSubject<boolean>(false);
  private downloadSubscription: Subscription;
  private downloadErrorSubject = new BehaviorSubject<string | null>(null);
  downloadError$ = this.downloadErrorSubject.asObservable();
  private cancelDownloadSubject = new Subject<void>();
  cancelDownload$ = this.cancelDownloadSubject.asObservable();

  selectDocument($event: Array<IRowNode>): void {
    this.selectedDocumentIds = [];
    $event.forEach((item) => {
      this.selectedDocumentIds.push(item.data.cod_documento);
    });
  }

  deselectDocument(id: number): void {
    this.selectedDocumentIds = this.selectedDocumentIds.filter(
      (docId) => docId !== id,
    );
  }

  getSelectedDocumentIds(): number[] {
    return this.selectedDocumentIds;
  }

  startDownload(): Observable<void> {
    if (this.selectedDocumentIds.length === 0) {
      return new Observable((observer) => observer.complete());
    }

    this.isDownloadingSubject.next(true);
    this.totalDownloads = this.selectedDocumentIds.length;
    this.completedDownloads = 0;
    this.downloadErrorSubject.next(null);

    return new Observable((observer) => {
      this.downloadSubscription = from(this.selectedDocumentIds)
        .pipe(
          concatMap((id) =>
            this.downloadFile(id).pipe(
              catchError((err) => {
                this.downloadErrorSubject.next(
                  "Si è verificato un errore durante il download",
                );
                throw err;
              }),
            ),
          ),
          toArray(), // Collect all downloaded documents into an array
        )
        .subscribe({
          next: (documents) => {
            if (documents.length === this.totalDownloads) {
              this.createZip(documents);
              this.downloadProgressSubject.next(100); // Download completato
              setTimeout(() => {
                this.reset();
                observer.next();
                observer.complete();
              }, 3000); // Nascondi il componente di caricamento dopo 3 secondi
            }
          },
          error: (err) => {
            console.error("Errore durante il download:", err);
            observer.error(err);
          },
        });
    });
  }

  private downloadFile(id: number): Observable<DocData> {
    return this._http
      .get<DocData>(`${environment.apiUrl}/webDocumenti/download/${id}`)
      .pipe(
        tap(() => {
          this.completedDownloads++;
          const progress =
            (this.completedDownloads / this.totalDownloads) * 100;
          this.downloadProgressSubject.next(progress);
        }),
      );
  }

  private createZip(documents: DocData[]): void {
    const zip = new JSZip();
    documents.forEach((doc) => {
      const fileData = this.b64toBlob(doc.contentbase64, doc.mimetype);
      zip.file(doc.filename, fileData);
    });
    zip.generateAsync({ type: "blob" }).then((content) => {
      saveAs(content, "downloaded_files.zip");
    });
  }

  private b64toBlob(
    b64Data: string,
    contentType: string = "",
    sliceSize: number = 512,
  ): Blob {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  cancelDownload(): void {
    if (this.downloadSubscription) {
      this.downloadSubscription.unsubscribe();
      this.reset();
      this.cancelDownloadSubject.next(); // Emissione dell'evento di annullamento
    }
  }

  private reset(): void {
    this.selectedDocumentIds = [];
    this.totalDownloads = 0;
    this.completedDownloads = 0;
    this.downloadProgressSubject.next(0);
    this.isDownloadingSubject.next(false);
    this.downloadErrorSubject.next(null); // Resetta il messaggio di errore
  }

  searchAllDocumenti(filter: {
    area?: string;
    cod_legame?: number;
    dt_docDa?: string;
    dt_docA?: string;
    cod_doctipo?: number;
    cod_documento?: number;
  }): Observable<Array<Documento>> {
    return this._http.post<Array<Documento>>(
      `${environment.apiUrl}/webDocumenti/search`,
      filter,
    );
  }

  searchDocumentiCorrelati(filter: {
    area?: string;
    cod_cliente?: number;
    cod_legame?: string;
  }): Observable<Array<DocumentoCorrelatoUtente>> {
    return this._http.post<Array<DocumentoCorrelatoUtente>>(
      `${environment.apiUrl}/webDocumenti/searchbyclient`,
      filter,
    );
  }

  downloadDocumento(cod_documento: number): Observable<DocData> {
    return this._http.get<DocData>(
      `${environment.apiUrl}/webDocumenti/download/${cod_documento}`,
    );
  }

  getDoc(cod_documento: number): Observable<any> {
    return this._http.get<any>(
      `${environment.apiUrl}/webDocumenti/getdoc/${cod_documento}`,
    );
  }

  getAllDocTipo(): Observable<Array<DocTipo>> {
    return this._http.get<Array<DocTipo>>(
      `${environment.apiUrl}/webDocumenti/doctipo`,
    );
  }

  getAree() {
    return this._http.get(`${environment.apiUrl}/webDocumenti/area`)
  }
}
