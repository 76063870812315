import {CoreMenu} from '@core/types';

export const menu: CoreMenu[] = [
  {
    id: 'home',
    title: 'Home',
    translate: 'MENU.HOME',
    type: 'item',
    icon: 'home',
    url: 'home'
  },
  {
    id: 'clienti',
    title: 'clienti',
    type: 'item',
    icon: 'users',
    url: 'clienti',
    roles: [2000],
  },
  //   children: [
  //     {
  //       id: 'anagraficaClienti',
  //       title: 'Clienti',
  //       type: 'item',
  //       icon: 'user',
  //       url: 'anagrafiche/clienti',
  //       roles: [2000],
  //     }
  //   ]
  // },
  // {
  //   id: 'anagrafiche',
  //   title: 'Anagrafiche',
  //   type: 'item',
  //   icon: 'person',
  //   roles: [2000],
  //   url: 'anagrafiche/clienti',
  // },
  // {
  //   id: 'polizze',
  //   title: 'Polizze',
  //   type: 'collapsible',
  //   icon: 'file-invoice',
  //   children: [
  //     {
  //       id: 'gestionePolizze',
  //       title: 'Gestione',
  //       type: 'item',
  //       icon: 'folder',
  //       url: 'polizze/gestione'
  //     }
  //   ]
  // },
  {
    id: 'polizze',
    title: 'Polizze',
    type: 'item',
    icon: 'file-invoice',
    url: 'polizze/gestione',
  },
  // {
  //   id: 'pagamenti',
  //   title: 'Pagamenti',
  //   type: 'collapsible',
  //   icon: 'file-invoice-dollar',
  //   roles: [1000],
  //   children: [
  //     {
  //       id: 'gestionePagamenti',
  //       title: 'Gestione',
  //       type: 'item',
  //       icon: 'folder',
  //       url: 'pagamenti/gestione',
  //       roles: [1000],
  //     }
  //   ]
  // },
  {
    id: 'pagamenti',
    title: 'Scadenze',
    type: 'item',
    icon: 'file-invoice-dollar',
    roles: [1000],
    url: 'pagamenti/gestione',
  },
  // {
  //   id: 'titoli',
  //   title: 'Titoli',
  //   type: 'collapsible',
  //   icon: 'dollar',
  //   roles: [2000],
  //   children: [
  //     {
  //       id: 'gestioneTitoli',
  //       title: 'Gestione',
  //       type: 'item',
  //       icon: 'folder',
  //       url: 'titoli/gestione',
  //       roles: [2000]
  //     }
  //   ]
  // },
  {
    id: 'titoli',
    title: 'Titoli',
    type: 'item',
    icon: 'dollar',
    roles: [2000],
    url: 'titoli/gestione',
  },
  {
    id: 'sinistri',
    title: 'Sinistri',
    type: 'item',
    icon: 'file',
    url: 'sinistri',
    roles: [1000]
  },
  // {
  //   id: 'comunicazioni',
  //   title: 'Comunicazioni',
  //   type: 'item',
  //   icon: 'message',
  //   url: 'comunicazioni',
  //   roles: [1000]
  // },
  {
    id: 'documenti',
    title: 'Documenti Cliente',
    type: 'item',
    icon: 'folder',
    url: 'documenti',
    roles: [1000]
  },
  /*{
    id: 'admin',
    type: 'section',
    title: 'Amministrazione',
    translate: 'MENU.ADMIN',
    children: [*/
  {
    id: 'richieste-contributi',
    title: 'Documenti Previdir',
    type: 'item',
    icon: 'file-invoice-dollar',
    roles: [2000],
    url: 'richieste-contributi',
  },
  // {
  //   id: 'documenti-contabili',
  //   title: 'Documenti',
  //   type: 'item',
  //   icon: 'file-invoice-dollar',
  //   roles: [2000],
  //   url: 'documenti-contabili',
  // },
  {
    id: 'invia-segnalazioni',
    title: 'Invia Segnalazione',
    type: 'item',
    icon: 'triangle-exclamation',
    classes: 'btn btn-outline-warning rounded text-warning'
  },
          // {
          //   id: 'invoices',
          //   title: 'Fatture Inviate',
          //   translate: 'MENU.SENT_INVOICES',
          //   type: 'item',
          //   icon: 'file-invoice',
          //   url: 'invoices/sent'
          // },
          // {
          //   id: 'clients',
          //   title: 'Clienti',
          //   translate: 'MENU.CLIENTS',
          //   type: 'item',
          //   icon: 'user',
          //   url: 'clients'
          // }
    //]
  //},
  // {
  //   id: 'form',
  //   title: 'Form wizard',
  //   type: 'item',
  //   icon: 'folder',
  //   url: 'form'
  // },
];
