<!--<div *ngIf="horizontalMenu" class="navbar-header d-xl-block d-none">

	<!-- <ul class="nav navbar-nav flex-row">
		<li class="nav-item">
			<a class="navbar-brand" (click)="redirectToHome()">
       <span class="brand-logo">
          <img src="{{ coreConfig.app.appLogoImage }}" alt="brand-logo" width="36"/>
        </span>
				<h2 class="brand-text mb-0">{{ coreConfig.app.appName }}</h2>
			</a>
		</li>
	</ul>

</div>-->

<div class="navbar-container d-flex content" style="max-width: 50%;">
	<div class="bookmark-wrapper d-flex align-items-center">
		<!-- Menu Toggler | Menu icon will be hidden in case of layout without menu -->
		<ul class="nav navbar-nav d-xl-none" *ngIf="!coreConfig.layout.menu.hidden">
			<li class="nav-item">
				<a class="nav-link menu-toggle" (click)="toggleSidebar('menu')">
					<span [data-feather]="'menu'" [class]="'ficon'"></span>
				</a>
			</li>
		</ul>
		<!--/ Menu Toggler -->
		<li class="nav-item d-block" *ngIf="coreConfig.layout.type==='horizontal'">
			<span class="font-weight-bolder" style="padding-left: 8px;">
				<a class="navbar-brand" (click)="redirectToHome()"><img style="max-width: 75%;" src="assets/images/logo/logo-mizar.png" width="312" alt="Logo Portale"/></a></span>
		</li>
	</div>

</div>

<div class="navbar-container d-flex content" style="max-width: 50%;">

	<ul class="nav navbar-nav align-items-center ml-auto btn btn-icon btn-outline-light" style="margin-left:auto;">


		<!-- <li class="nav-item d-none d-lg-block">
			<a class="nav-link nav-link-style btn" (click)="toggleDarkSkin()">
				<span [ngClass]="currentSkin === 'dark' ? 'icon-sun' : 'icon-moon'" class="ficon font-medium-5 feather"></span>
			</a>
		</li> -->

		<!-- <app-navbar-search></app-navbar-search>

		<app-navbar-notification></app-navbar-notification> -->

<!--        <div class="me-1">-->
<!--            <button class='btn btn-warning btn-icon' (click)="openModalSupport()">-->
<!--                <fa-icon [icon]="'triangle-exclamation'"></fa-icon>-->
<!--            </button>-->
<!--        </div>-->

		<li ngbDropdown class="nav-item dropdown-user">
			<a
				class="nav-link dropdown-toggle dropdown-user-link"
				id="dropdown-user"
				ngbDropdownToggle
				id="navbarUserDropdown"
				aria-haspopup="true"
				aria-expanded="false"
			>
				<ng-container >
					<div class="user-nav d-lg-flex d-none">
            <span class="user-name font-weight-bolder"
			>{{user?.value?.name}}</span
			><span class="user-status" *ngIf="intermediario || clienti.length > 0">{{user.value.rolecode === 1000 ? clienti[0]?.nominativo : intermediario?.nominativo}}</span>
					</div>
					<span class="avatar">
						<i class="feather icon-user"></i>
					</span>
				</ng-container>
			</a>
			<div ngbDropdownMenu aria-labelledby="navbarUserDropdown" class="dropdown-menu dropdown-menu-right">
				<!-- <a ngbDropdownItem [routerLink]="['/pages/profile']"
				><span [data-feather]="'user'" [class]="'mr-50'"></span> Profile</a
				><a ngbDropdownItem [routerLink]="['/apps/email']"
			><span [data-feather]="'mail'" [class]="'mr-50'"></span> Inbox</a
			><a ngbDropdownItem [routerLink]="['/apps/todo']"
			><span [data-feather]="'check-square'" [class]="'mr-50'"></span> Task</a
			><a ngbDropdownItem [routerLink]="['/apps/chat']"
			><span [data-feather]="'message-square'" [class]="'mr-50'"></span> Chats</a
			>
				<div class="dropdown-divider"></div>
				<a ngbDropdownItem [routerLink]="['/pages/account-settings']"
				><span [data-feather]="'settings'" [class]="'mr-50'"></span> Settings</a
				><a ngbDropdownItem [routerLink]="['/pages/pricing']"
			><span [data-feather]="'credit-card'" [class]="'mr-50'"></span> Pricing</a
			><a ngbDropdownItem [routerLink]="['/pages/faq']"
			><span [data-feather]="'help-circle'" [class]="'mr-50'"></span> FAQ</a> -->
			<a ngbDropdownItem [routerLink]="['/profilo']"><span [data-feather]="'user'" [class]="'mr-50'"></span> Profilo</a>
			<a *ngIf="user?.value?.rolecode === 2000" ngbDropdownItem [routerLink]="['/documenti-contabili']"><span [data-feather]="'file'" [class]="'mr-50'"></span> <span class="d-inline d-sm-none">Doc.</span><span class="d-none d-sm-inline">Documenti</span> Amministrativi</a>
			<a ngbDropdownItem (click)="logout()"><span [data-feather]="'power'" [class]="'mr-50'"></span> Logout</a>
			</div>
		</li>
	</ul>
</div>
