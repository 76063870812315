<!-- Footer -->
<p class="clearfix mb-0">
  <span class="float-md-start d-block d-md-inline-block mt-25">
    COPYRIGHT &copy; {{ year }} {{ coreConfig.app.appName }}
    <span class="d-none d-sm-inline-block">| All rights Reserved</span>
  </span>
  <span class="float-md-end d-none d-md-block">
    By <a target="_blank" href="https://arsdue.com">Arsdue</a>
  </span>
</p>
<!--/ Footer -->

<!-- Move to top Button-->
<app-scroll-top *ngIf="coreConfig.layout.scrollTop"></app-scroll-top>
