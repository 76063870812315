<div class='row mb-1 justify-content-end'>
	<div class='col-sm-4 col-12' *ngIf='secondButtonTxt'>
		<button
			[disabled]='secondButtonDisabled'
			class='form-control btn btn-table btn-{{ secondButtonClass }}'
			(click)='secondButtonEvent($event)'
		>
			{{ secondButtonTxt }}
		</button>
	</div>


	<div class='col-sm-4 col-12' *ngIf='actionButtonTxt'>
		<button
			[disabled]='actionButtonDisabled'
			class='form-control btn btn-table btn-{{ actionButtonClass }}'
			(click)='actionButtonEvent($event)'
		>
			{{ actionButtonTxt }}
		</button>
	</div>
</div>


<div *ngIf='!simple' class='row mb-1'>
	<div class='col-sm-2 col-xxl-1 col-4 mb-1 mb-sm-0' *ngIf='actions.length === 1'>
		<button [class]="'w-100 h-100 btn btn-' + actions[0].icon?.class" (click)='actions[0].click()' ngbTooltip='{{actions[0].txt}}' [disabled]='actions[0].disabled()'>
			<fa-icon [icon]='actions[0].icon.name'></fa-icon>
		</button>
	</div>

	<div class='col-sm-2 col-xxl-1 col-4 mb-1 mb-sm-0' *ngIf='actions.length > 1'>
		<div ngbDropdown class='h-100'>
			<button
				ngbDropdownToggle
				id='action-core-table'
				class='btn-icon btn btn-primary btn-round h-100 w-100'
				type='button'
				rippleEffect
			>
				<!--				<span [data-feather]="'grid'"></span>-->
				<fa-icon icon='cogs'></fa-icon>
			</button>

			<div ngbDropdownMenu aria-labelledby='action-core-table'>
				<a *ngFor='let action of actions' ngbDropdownItem [disabled]='action.disabled()' (click)='action.click()'>
					<ng-container *ngIf='action.icon'>
						<fa-icon *ngIf='action.icon?.class !== ""; else noClass' [icon]='action.icon.name' [class]='"text-"+action.icon.class'></fa-icon>
						<ng-template #noClass>
							<fa-icon [icon]='action.icon.name'></fa-icon>
						</ng-template>
					</ng-container>
					<span class='ms-1'>{{action.txt}}</span>
				</a>

			</div>
		</div>
	</div>
	<div class='col-sm-2 col-md-2 col-4 mb-1 mb-sm-0'>
		<!--<span class='d-none d-lg-inline-flex' style='margin-top: 8px;'
		>Record per pagina:
		</span>-->
		<ng-select
			(change)='onPageSizeChanged()'
			id='page-size'
			style='height: 100%;'
			[(ngModel)]='pageSize'
            [clearAllText]="'Cancella'"
		>
			<ng-option value='30' selected=''>30 per pagina</ng-option>
			<ng-option value='100'>100 per pagina</ng-option>
			<ng-option value='300'>300 per pagina</ng-option>
			<ng-option value='500'>500 per pagina</ng-option>
		</ng-select>
	</div>

	<div class='col-auto d-flex justify-content-center'>
		<button
			class='customColor btn fw-bold'
			(click)='onBtnExport()'
		>
            <fa-icon icon='file-excel' style="font-size: 16px"></fa-icon>
<!--			ESPORTA-->
		</button>
	</div>

	<div class='col'> <!-- col-sm-5 col-md-6 col-xxl-8 col-12 -->
		<input
			type='text'
			id='filter-text-box'
			class='form-control'
			style='width: 100%; margin-bottom: 6px; height: 100%'
			placeholder='Ricerca...'
			(input)='onFilterTextBoxChanged()'
		/>
	</div>
</div>


<ag-grid-angular
	style='height: 500px; display: contents'
	class='ag-theme-material'
	[rowData]='rowData'
	[columnDefs]='columnDefs'
	(gridReady)='onGridReady($event)'
	(gridSizeChanged)='onGridSizeChanged()'
	(selectionChanged)='emitRows($event)'
	[animateRows]='true'
	[pagination]='!simple'
	[localeText]='tableLanguage'
	[paginationPageSize]='30'
	[gridOptions]='gridOptions'
	[rowSelection]='rowSelection'
	[isRowSelectable]='isRowSelectable'
	domLayout='autoHeight'
	suppressRowClickSelection='true'
	[editType]='editType'
	(rowValueChanged)='emitEditedRow($event)'
	(cellValueChanged)='onCellValueChanged($event)'
	(filterChanged)='saveFilterModel()'
	(firstDataRendered)='onFirstDataRendered($event)'
	(rowDataUpdated)='onRowDataUpdated($event)'
>
</ag-grid-angular>
<!--stopEditingWhenCellsLoseFocus=true-->
